<template>
  <base-dropdown
    v-if="savedSearches && savedSearches.length > 0"
    dropdown-class="mt-2 w-72 rounded-sm shadow-lg z-10 bg-white border border-gray-300"
  >
    <template
      #dropdownToggleBtn="{
        handleDropdownToggle
      }"
    >
      <base-button
        tag="a"
        variant="btn-primary"
        href="javascript:void(0)"
        class="flex items-center mr-1.5 transform -skew-x-25 font-semibold"
        @click="handleDropdownToggle()"
      >
        <template #default>
          <span class="mr-1.5 transform skew-x-25 truncate">
            Saved Searches
          </span>
          <base-svg
            class="h-3.5 w-3.5 transform skew-x-25 text-white"
            src="icons/arrowDownWide.svg"
            :svg-attributes="{
              class: 'h-full w-full'
            }"
            tag="span"
          />
        </template>
      </base-button>
    </template>
    <template #dropdownContent="{ handleDropdownToggle }">
      <ul class="list-none">
        <li
          v-for="(searchOption, sIndex) in savedSearches"
          :key="'searchOption' + sIndex"
          class="flex justify-between items-center px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
          role="menuitem"
          :class="searchOption.id === selectedSearch.id ? 'text-gray-900 bg-gray-100' : 'text-primary-gray'"
        >
          <span
            class="w-5/6 break-words"
            @click="handleSelectedSearch(searchOption, handleDropdownToggle)"
          >
            {{ searchOption.name ? searchOption.name : searchOption.searchName }}
          </span>
          <base-svg
            v-if="!proxyUser"
            class="h-3.5 w-3.5 text-primary-gray inline-block hover:text-gray-900"
            src="icons/cross.svg"
            :svg-attributes="{
              class: 'h-full w-full'
            }"
            tag="span"
            @click.stop="confirmDeletion(searchOption)"
          />
        </li>
      </ul>
    </template>
  </base-dropdown>
  <!-- confirm delete search modal -->
  <confirm-delete-search-modal
    v-model="showDeleteSearchModal"
    @accepted="handleDelete()"
    @rejected="setDeleteSearchModalVisibility(false)"
  />
</template>

<script>
import { ref, computed, onMounted, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import BaseDropdown from '@/components/generic-components/BaseDropdown.vue';
import BaseButton from '@/components/generic-components/BaseButton.vue';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { DELETE_SEARCH_FAILED, DELETE_SEARCH_SUCCESS, ERROR, SUCCESS } from '@/constants/alerts';

export default {
    name: 'SavedSearchesList',

    components: {
        BaseDropdown,
        BaseButton,
        BaseSvg,
        ConfirmDeleteSearchModal: defineAsyncComponent(() => import('./ConfirmDeleteSearchModal.vue'))
    },

    setup (props, { attrs }) {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const { notificationsStack } = useToastNotifications();
        const proxyUser = computed(() => store.getters['auth/getUserProxyInfo']);

        // select search logic
        const selectedSearch = ref('');
        const { searchAssets, saveSearchParams, resetAssetsAndSearch, clearSearchParams } = useStyleGuideAssets();
        const handleSelectedSearch = (selectedOption, handleDropdownToggle) => {
            selectedSearch.value = selectedOption;
            handleDropdownToggle();
            loadSearch(selectedOption);
        };
        const loadSearch = async (selectedOption) => {
            clearSearchParams()
            resetAssetsAndSearch()
            store.dispatch('assets/setSearchPage', 1 )
            await saveSearchParams({ ...selectedOption.searchParams })
            await searchAssets();
            if (route.name!='StyleGuide') router.push({ name: 'StyleGuide' });
        };

        // fetch saved searches logic
        const savedSearches = computed(() => store.getters['assetsSearch/getSavedSearches']);
        const savedSearchesLoading = ref(false);
        const fetchSavedSearches = async () => {
            try {
                savedSearchesLoading.value = true;
                await store.dispatch('assetsSearch/fetchSavedSearches');
            } catch (err) {
                console.error(err);
            } finally {
                savedSearchesLoading.value = true;
            }
        };

        // delete search logic
        const showDeleteSearchModal = ref(false);
        let selectedSearchOption = null;
        const confirmDeletion = (searchOption) => {
            selectedSearchOption = searchOption;
            setDeleteSearchModalVisibility(true);
        };
        const isSearchDeleting = ref(false);
        const handleDelete = async () => {
            if (isSearchDeleting.value) {
                return;
            }
            try {
                isSearchDeleting.value = true;
                setDeleteSearchModalVisibility(false);
                await store.dispatch('assetsSearch/deleteSavedSearch', selectedSearchOption);
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: DELETE_SEARCH_SUCCESS
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: DELETE_SEARCH_FAILED
                });
            } finally {
                isSearchDeleting.value = false;
            }
        };
        const setDeleteSearchModalVisibility = (visibility) => {
            showDeleteSearchModal.value = visibility;
        };

        onMounted(() => {
            fetchSavedSearches();
        });

        return {
            savedSearches,
            savedSearchesLoading,
            selectedSearch,
            handleSelectedSearch,
            attrs,
            confirmDeletion,
            handleDelete,
            showDeleteSearchModal,
            setDeleteSearchModalVisibility,
            proxyUser
        };
    }
};
</script>
